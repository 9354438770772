<template>
  <div>
    <!-- <div class="container"> -->

    <v-card class="container" style="margin-top:40px;">
      <v-row>
        <v-col cols="12">
          <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0">
            <v-toolbar-title>List Vendor</v-toolbar-title>
            <v-divider class="mx-6" inset vertical></v-divider>
            <div
              style="display:flex; justify-content:center; align-items:center;"
            >
              <v-text-field
                v-model="paramAPI.keyword"
                @keyup.enter="searchEnter"
                label="Search here"
                type="search"
                outlined
                append-icon="mdi-magnify"
                style="position: relative; top: 15px; width: 410px"
              ></v-text-field>

              <div
                style="display:flex;justify-content:space-evenly; align-items:center; width:300px;height:35px; "
              >
                <p style="margin:0; font-weight:bold;">Sort by</p>
                <p class="vendorSortIcon" @click="vCode">
                  code:
                  <v-icon small style=" box-shadow: 1px 1px 5px black;">{{
                    vCodeIcon
                  }}</v-icon>
                </p>
                <p class="vendorSortIcon" @click="vName">
                  name:
                  <v-icon small style=" box-shadow: 1px 1px 5px black;">{{
                    vNameIcon
                  }}</v-icon>
                </p>
              </div>
            </div>
          </v-toolbar>
        </v-col>

        <v-col cols="12">
          <v-data-table
            mobile-breakpoint="0"
            class="elevation-1"
            style="cursor:pointer;"
            :headers="headers"
            :items="result"
            :items-per-page="paramAPI.limit"
            @click:row="rowClick"
            hide-default-footer
          >
            <template v-slot:[`item.business_entity`]="{ item }">
              <td>
                {{
                  item.business_entity !== null
                    ? item.business_entity.name
                    : '-'
                }}
              </td>
            </template>
            <!-- <template v-slot:[`item.currency`]="{ item }">
              <td>
                {{ item.currency !== null ? item.currency.name : '-' }}
              </td>
            </template> -->
            <template v-slot:[`item.status`]="{ item }">
              <td>
                {{
                  item.status === 1
                    ? 'Active'
                    : item.status === 0
                    ? 'Inactive'
                    : 'Blocked'
                }}
              </td>
            </template>
          </v-data-table>
          <div style="display:flex;">
            <v-pagination
              style="margin-right:auto; "
              v-model="paramAPI.page"
              total-visible="7"
              :length="Math.ceil(paramAPI.length / paramAPI.limit) || 1"
              circle
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <div>
      <DetailVendor :result="detail" :rowData="selected" />
    </div>

    <!-- </div> -->
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  components: {
    DetailVendor: () =>
      import('../../components/eCatalogue/vendor_detail/VendorDetail.vue')
  },
  data: () => ({
    proc: buildType.apiURL('proc'),
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      length: 0,
      sortBy: 'name',
      sortType: 'asc'
    },
    headers: [
      //   {
      //     text: 'No',
      //     value: 'no',
      //     align: 'left',
      //     sortable: false
      //   },
      {
        text: 'Kode Vendor',
        value: 'code',
        align: 'left',
        sortable: true
      },
      {
        text: 'Nama Vendor',
        value: 'name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Badan Usaha',
        value: 'business_entity',
        align: 'left',
        sortable: false
      },
      //   {
      //     text: 'Currency',
      //     value: 'currency',
      //     align: 'left',
      //     sortable: false
      //   },
      {
        text: 'Kontak 1',
        value: 'phone1',
        align: 'left',
        sortable: false
      },
      {
        text: 'Kontak 2',
        value: 'phone2',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    detail: null,
    selected: null,
    vCodeIcon: 'mdi-arrow-up',
    vNameIcon: 'mdi-arrow-up'
  }),
  watch: {
    'paramAPI.page'() {
      this.paramAPI.offset = this.paramAPI.page - 1
      this.initDataTable()
    },
    'paramAPI.sortBy'() {
      this.initDataTable()
    },
    'paramAPI.sortType'() {
      this.initDataTable()
    }
  },
  created() {
    this.initDataTable()
  },
  methods: {
    rowClick(pValue) {
      this.selected = pValue
      axios
        .get(`${this.proc}vendor/detail/${pValue.id}`)
        .then(res => {
          console.log(res.data.data)
          this.detail = res.data.data
          document.getElementById('vendorDetail').click()
        })
        .catch(err => {
          console.log(err)
        })
    },
    initDataTable() {
      axios
        .get(
          `${this.proc}vendor/list?keyword=${
            this.paramAPI.keyword
          }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
            this.paramAPI.limit
          }&order_by=${this.paramAPI.sortBy}&order_type=${
            this.paramAPI.sortType
          }`
        )
        .then(res => {
          this.paramAPI.length = res.data.total_record
          this.result = res.data.data
          console.log(this.result)
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchEnter() {
      this.initDataTable()
    },
    vCode() {
      this.paramAPI.sortBy = 'code'
      if (this.vCodeIcon === 'mdi-arrow-up') {
        this.vCodeIcon = 'mdi-arrow-down'
        this.paramAPI.sortType = 'desc'
      } else {
        this.vCodeIcon = 'mdi-arrow-up'
        this.paramAPI.sortType = 'asc'
      }
    },
    vName() {
      this.paramAPI.sortBy = 'name'
      if (this.vNameIcon === 'mdi-arrow-up') {
        this.vNameIcon = 'mdi-arrow-down'
        this.paramAPI.sortType = 'desc'
      } else {
        this.vNameIcon = 'mdi-arrow-up'
        this.paramAPI.sortType = 'asc'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vendorSortIcon {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.486);
  padding: 2px 5px;
  border-radius: 3px;
  cursor: pointer;
}
</style>
