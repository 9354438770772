<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="80%">
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip top v-bind="attrs" v-on="on">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="1"
              style="z-index:1; right:3px; bottom:3px;"
              v-bind="attrs"
              v-on="on"
              absolute
              class="white--text"
              fab
              x-small
              @click="viewImage(items)"
            >
              <v-icon
                color="indigo"
                style="filter:drop-shadow(2px 2px 1px #FFFF8D);"
                >mdi-image-search</v-icon
              >
            </v-btn>
          </template>
          <span>Lihat gambar</span>
        </v-tooltip>
      </template>

      <v-card>
        <div>
          <v-carousel hide-delimiters>
            <!-- <v-carousel-item
              v-for="(item, index) in link"
              :key="index"
              :src="
                item.src === null
                  ? `https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-ys19dvd8nvl3xqlu1o4u2z.png`
                  : production
                  ? `${item.src}`
                  : `${getProductImageLink}${item.src}`
              "
              contain
            > -->
            <v-carousel-item
              v-for="(item, index) in imgArr"
              :key="index"
              :src="item"
              contain
            >
              <v-sheet
                color="transparent"
                height="100%"
                style="position:relative;"
              >
                <div
                  class="text-h2 black--text"
                  style="position:absolute;bottom:0; left:0;width:100%;"
                >
                  <div
                    class="d-flex flex-column justify-center align-center"
                    style="padding:10px;"
                  >
                    <p
                      style="margin:0; color:black; font-size:18px;text-shadow: 1px 1px 10px rgb(255,255,255); font-weight:bold;line-height:20px;"
                    >
                      {{ items.product.name + '| #' + items.product.code }}
                    </p>
                  </div>
                </div>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['items'],
  data() {
    return {
      production:
        process.env.VUE_APP_BUILD_TYPE === 'production' ||
        process.env.VUE_APP_BUILD_TYPE === 'domain' ||
        process.env.VUE_APP_BUILD_TYPE === 'preproduction',
      dialog: false,
      link: [
        {
          src: ''
        },
        {
          src: ''
        },
        {
          src: ''
        },
        {
          src: ''
        },
        {
          src: ''
        }
      ],
      imgArr: [],
      str_photo: '',
      slide: 0,
      sliding: null
    }
  },
  computed: {
    ...mapGetters(['getProductImageLink'])
  },
  methods: {
    viewImage(data) {
      console.log(data)
      this.imgArr = []
      const arr = Object.values(data.photo)
      const img = []

      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== null) {
          img.push(
            arr[i].replace(
              'http://localhost/uploads/',
              `${this.getProductImageLink}`
            )
          )
        } else {
          if (i === 0) {
            img.push(
              'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-ys19dvd8nvl3xqlu1o4u2z.png'
            )
          }
        }
      }

      this.imgArr = img
      this.dialog = true
      // console.log(data)
      // this.link[0].src =
      //   data.photo.photo_1 !== null
      //     ? data.photo.photo_1.replace('http://localhost/uploads/', '')
      //     : null
      // this.link[1].src =
      //   data.photo.photo_2 !== null
      //     ? data.photo.photo_2.replace('http://localhost/uploads/', '')
      //     : null
      // this.link[2].src =
      //   data.photo.photo_3 !== null
      //     ? data.photo.photo_3.replace('http://localhost/uploads/', '')
      //     : null
      // this.link[3].src =
      //   data.photo.photo_4 !== null
      //     ? data.photo.photo_4.replace('http://localhost/uploads/', '')
      //     : null
      // this.link[4].src =
      //   data.photo.photo_5 !== null
      //     ? data.photo.photo_5.replace('http://localhost/uploads/', '')
      //     : null
      this.dialog = true
    },
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>
